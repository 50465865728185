export const IFRAME_ID = "identifier";

export const DEMO_MODE = "demo";
export const GUEST_MODE = "guest";
export const REAL_MODE = "real";

export const APLAY = "aplay";
export const DRIFT = "drift";
export const FRANK = "frank";
export const MRBIT = "mrbit";
export const SLOTV = "slotv";
export const ASTRAJAM = "astrajam";

export const CRICV_DOMAINS = ["cricv.com", "web1-cw-india.slotv.com", "web2-cw-india.slotv.com"]

export const WEB_DESKTOP = "web_desktop";
export const WEB_MOBILE = "web_mobile";
export const APP_MOBILE = "app_mobile";

export const A7_JURISDICTION = "A7";
export const BG_JURISDICTION = "BG";
export const CW_JURISDICTION = "CW";
export const DE_JURISDICTION = "DE";
export const MT_JURISDICTION = "MT";
export const NL_JURISDICTION = "NL";
export const RO_JURISDICTION = "RO";
export const SE_JURISDICTION = "SE";

export const INDONESIA_CONTRY_CODE = "ID";

export const COUNTRY_CODE_KEY = "country_code";
export const DEVICE_TYPE_KEY = "device_type_code";
export const GAME_NAME_KEY = "game_name";
export const GAME_URL_KEY = "url";
export const HIDE_UI_KEY = "hide_ui";
export const JURISDICTION_KEY = "jurisdiction";
export const LOCALE_KEY = "locale";
export const MODE_KEY = "mode";
export const PLATFORM_NAME_KEY = "platform_name";
export const PLAY_FOR_REAL_URL_KEY = "play_for_real";
export const PROJECT_NAME_KEY = "project_name";
export const REDIRECT_URL_KEY = "url";
export const TOKEN_KEY = "token";

export const ADD_TO_FAVORITES = "ADD_TO_FAVORITES";
export const BLOCK_YOURSELF_COMMAND = "BLOCK_YOURSELF";
export const GET_TOKEN_COMMAND = "GET_TOKEN";
export const LOGOUT_COMMAND = "LOGOUT";
export const OPEN_CASHBOX_COMMAND = "OPEN_CASHBOX";
export const OPEN_GAME_COMMAND = "OPEN_GAME";
export const OPEN_LOBBY_COMMAND = "OPEN_LOBBY";
export const OPEN_PROFILE_COMMAND = "OPEN_PROFILE";
export const OPEN_SUPPORT_COMMAND = "OPEN_SUPPORT_CHAT";
export const PLAY_FOR_REAL_COMMAND = "PLAY_FOR_REAL";
export const RELOAD_COMMAND = "RELOAD";
export const REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES";

export const IFRAME_STRATEGY = "iframe";
export const POST_MESSAGE_STRATEGY = "postMessage";
export const REDIRECT_STRATEGY = "redirect";
export const WINDOW_STRATEGY = "window";

export const NOTIFICATION_MESSAGE_DIALOG_TYPE = "notificationMessageDialogType";
export const ERROR_MESSAGE_DIALOG_TYPE = "errorMessageDialogType";
export const WARNING_MESSAGE_DIALOG_TYPE = "warningMessageDialogType";
export const LOW_BALANCE_DIALOG_TYPE = "lowBalanceDialogType";
export const MENU_DIALOG_TYPE = "menuDialogType";
export const ORIENTATION_CHANGED_DIALOG_TYPE = "orientationWasChangedDialog";
export const USER_LIMITS_DIALOG_TYPE = "userLimitsDialogType";

export const USER_PROGRESS_WIDGET = "userprogress";
export const WAGER_PROGRESS_WIDGET = "wageringprogress";
export const DAILY_CHALLENGES_WIDGET = "dailychallenges";
export const TOURNAMENTS_WIDGET = "tournaments";
export const PENDING_WITHDRAWALS_WIDGET = "pendingwithdrawals";
export const INSUFFICIENT_BALANCE_WIDGET = "insufficientbalance";
export const RECENT_GAMES_WIDGET = "recentgames";

export const MESSAGE_KIND_NOTIFICATION = "notification";
export const MESSAGE_KIND_ERROR = "error";
export const MESSAGE_KIND_WARNING = "warning";
export const MESSAGE_KIND_LOW_BALANCE = "low_balance";
